import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/SEOPage';
import Layout from '../components/shared/layout';
import { breakpoints, wrapper } from '../utils/style';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from '../components/shared/ButtonLink';
import Parallax from "../components/shared/Parallax"

const Wrapper = styled('div')`
  ${wrapper}
  margin: 30px auto;
  border-radius: 28px;
  background-color: rgba(255, 255, 255, .08);
  min-height: calc(100vh - 500px);
  padding: 150px 0;
  .img-wrap {
    max-width: 280px;
    margin: 0 auto 50px;
  }
  .bottom-block {
    text-align: center;
    h1 {
      margin: 56px 0;
      font-size: 52px;
      font-weight: 800;
    }
    .bottom-button {
      font-size: 16px;
      font-weight: bold;
      padding: 0 50px;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    margin: 20px auto;
    padding: 30px 0;
    min-height: auto;
    width: 90%;
    .img-wrap {
      max-width: 250px;
    }
    .bottom-block {
      h1 {
        font-size: 18px;
        margin: 32px 0 24px;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .img-wrap {
      max-width: 230px;
    }
  }
`;

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title='404: Not found'/>
    <Parallax />
    <Wrapper>
      <div className="img-wrap">
        {data.missing.childImageSharp.fluid ?
          <Img alt="404" fluid={data.missing.childImageSharp.fluid} fadeIn={false} loading='eager' />
          : "404"
        }
      </div>
      <div className="bottom-block">
        <ButtonLink to="/" iconName='' className="button gradient-button bottom-button" text="GO TO HOMEPAGE" ariaText="GO TO HOMEPAGE"/>
      </div>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    missing: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
          fluid(maxWidth: 280) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }   
  }
`;
